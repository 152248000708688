<template>
    <div class="modal-meeting-place-input">
        <div class="head m-b-16">
            <div class="title">만남장소 입력하기</div>
        </div>

        <div>약속을 확정합니다. 상대방도 약속을 확정하면, 약속 최종 안내를 드리겠습니다.</div>
        <div class="notice m-t-4">네이버 지도를 기준으로 정보를 입력해주세요.</div>
        <TextareaWithX
            :isInputMode="true"
            :placeholder="'장소명 입력(ex. 스타벅스 역삼역점)'"
            v-model="restaurantName"
            class="m-t-8 m-b-16"
        />
        <TextareaWithX
            :isInputMode="true"
            :placeholder="'주소명 입력(ex. naver.me/)'"
            v-model="restaurantUrl"
            class="m-t-8 m-b-16"
        />
        <BottomButton
            :nonFixed="true"
            :blackBtn="true"
            :disabled="disabled"
            :label="'장소 입력하기'"
            @click="onSubmitMapUrl"
        />
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ModalMeetingPlaceInput',
    props: ['options'],
    data: () => ({
        restaurantUrl: null,
        // place : null,
        // place: 'test',
        restaurantName: null,
    }),
    mounted() {
        console.log('mounted')
    },

    computed: {
        disabled() {
            if (!(this.restaurantName && this.restaurantUrl)) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        async onSubmitMapUrl() {
            // submit action
            try {
                const res = await scheduleService.addReservation({
                    url: this.restaurantUrl,
                    schedule_id: this.options.schedule_id,
                    restaurant_name: this.restaurantName,
                })

                if (res.res === 'naver_only') {
                    await this.openModalWrongTypeAddress()
                } else if (res.res === 'success') {
                    await this.openModalMeetingAddressFinished()
                }
            } catch (e) {
                console.log(e)
            } finally {
                this.$emit('close')
            }
        },
        async openModalWrongTypeAddress() {
            const idx = await this.$modal.basic({
                body: '네이버 지도 주소만 입력할 수 있습니다.',
                buttons: [
                    {
                        label: '확인',
                        class: 'btn-default',
                    },
                    {
                        label: '네이버지도 바로가기',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                this.$nativeBridge.postMessage({
                    action: 'openInAppBrowser',
                    value: `https://map.naver.com/`,
                })
            }
        },
        async openModalMeetingAddressFinished() {
            const idx = await this.$modal.basic({
                body: `${this.restaurantName}을(를) 만남 장소로 등록합니다. 등록 시 상대 여성분에게도 안내 sms가 전송됩니다.<br/><br/>여성분이 등록한 장소에서 보기 어렵거나 먹기 힘든 음식 종류라고 말씀해주시면, 회원님께 매니저가 전달드릴 예정이에요.<br/><br/>매니저가 별 말이 없다면 여성분도 동의하신거니 '여자분이 이 장소 괜찮아할까?' 라고 걱정하지 않으셔도 좋습니다.`,
                buttons: [
                    {
                        label: '확인',
                        class: 'btn-primary',
                    },
                ],
            })
            console.log('idx', idx)
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-meeting-place-input {
    ::v-deep .textarea-wrapper {
        padding: 0 10px;
    }

    margin: 0 24px;
    .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #111111;
    }
    .notice {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #ff3d6b;
    }
}
</style>
